/* Custom styles */
:root {
  --primary: #f2faff;
  --secondary: #f6c142;
  --body-bg: #2b2d30;
  --body-color: #d5e6e4;
  --font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --h1-color: var(--primary);
  --icon-color: var(--primary);
}

/* Bootstrap variables */
:root {
  --bs-primary: var(--primary);
  --bs-secondary: var(--secondary);
}

/* Apply styles globally */
body {
  text-align: center;
  background-color: var(--body-bg);
  color: var(--body-color);
  font-family: var(--font-family-sans-serif);
}

/* Other custom CSS */
h1 {
  color: var(--h1-color);
  padding-top: 20px;
}

h2 {
  text-align: left;
  color: var(--secondary);
  font-weight: bolder;
}

h3 {
  text-align: left;
  color: var(--secondary);
  font-weight: bolder;
}

h5 {
  text-align: center;
  color: var(--body-color);
  font-weight: 600;
  font-style: italic;
}

p {
  text-align: justify;
}

/* Unvisited links */
a {
  color: var(--icon-color);
  text-decoration: none; /* Optional: remove underline */
}

/* Visited links */
a:visited {
  color: var(--icon-color);
}

/* Hovered links */
a:hover {
  color: var(--icon-color);
  text-decoration: underline; /* Optional: underline on hover */
}

/* Active links (when clicked) */
a:active {
  color: var(--icon-color);
}

.footer {
  background-image: url("../src/Photos/AvancaGare001.png");
  background-size: cover;
}

#cartaz {
  padding-top: 6%;
  padding-bottom: 3%;
  background-image: url("../src/Photos/cartaz.png");
  background-size: cover;
  overflow: hidden;
  background-position: bottom;
}

#logo {
  width: 15%;
  height: auto;
  filter: drop-shadow(5px 5px 5px #222);
}

#icon {
  filter: drop-shadow(5px 5px 5px #222);
}

@media (max-width: 700px) {
  #logo {
    width: 40%;
    height: auto;
  }
}

@media (min-width: 1026px) {
  #grid {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  #grid > div {
    flex-basis: 22%;
  }
}

@media only screen and (max-device-width: 400px) {
  #grid {
    min-height: 100%;
    justify-content: center;
  }

  #grid > div {
  }
}

@media (max-width: 600px) {
  .max-lines-xs {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Set the number of lines you want to display */
    -webkit-box-orient: vertical;
    cursor: pointer; /* Add cursor pointer to indicate the paragraph is clickable */
  }

  .full-text-xs {
    overflow: visible; /* Show full text */
    -webkit-line-clamp: unset; /* Remove line clamp */
  }
}

.max-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* Set the number of lines you want to display */
  -webkit-box-orient: vertical;
  cursor: pointer; /* Add cursor pointer to indicate the paragraph is clickable */
}

.full-text {
  overflow: visible; /* Show full text */
  -webkit-line-clamp: unset; /* Remove line clamp */
}

.cardText {
  text-align: center;
  font-style: italic;
  font-size: small;
}

.cardTitle {
  font-size: x-large;
}

iframe {
  height: 20%;
  width: 100%;
}

#alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
}


/* You can also import Bootstrap CSS instead of SCSS */
@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
